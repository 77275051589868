.book-layout {
    background-color: #eee;
}

.book-add {
    background-color: #eee;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 300000;
    overflow-y: auto;
}

.book-title {
    font-size: 3rem;
    color: black;
    font-weight: 600;
    text-align: center;
    padding: 30px 0px;
    margin: 0;
}

.book-container {
    padding: 15px 60px;
    background-color: white;
    border: solid 1px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
}

.book_notation {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.book_note {
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 8px;
    margin-right: 8px;
    font-style: italic;
}

.book-item-subtitle {
    margin: 20px 10px;
}

.book-item-header {
    display: inline-block;
    font-size: 20px;
    color: black;
    height: 28px;
    padding: auto;
}

.book-item-index {
    display: inline-block;
    font-size: 20px;
    color: white;
    background-color: #fa616a;
    margin-right: 15px;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    text-align: center;
}

.btn-book {
    width: 60%;
	color: white;
	background-color: #3063FF;
	border-color: #3063FF;
	margin: 10px 0px;
	padding: 10px 20px;
	font-size: 20px;
	font-weight: 600;
}

.btn-book:hover {
	color: white;
	background-color: #3063FF;
	border-color: #3063FF;
}

.btn-book:active {
	background-color: #fa616a;
	border-color: #fa616a;
	color: #000;
}

.btn-book:focus {
	background-color: #fa616a;
	border-color: #fa616a;
	color: #000;
}

.book-info-layout {
    padding: 20px 30px;
    background-color: #eee;
}

.book-item-view {
    color: black;
    font-size: 24px;
    padding: 8px 10px;
    border: solid 1px rgba(0, 0, 0, 0.2);
}

.camera-view {
    position: relative;
    width: 90%;
    height: 300px;
    margin: 5%;
    border-radius: 10px;
    overflow: hidden;
}

.book-date-time {
    display: flex;
    flex-direction: row;
}

.book-date-control {
    display: inline-block;
    margin-right: 5px;
}

.book-time-warning {
    color: red;
    font-size: 11px;
    margin-top: 3px;
}

.book-time-controls {
    display: flex;
    flex-direction: row;
}

.book-time-control {
    display: inline-block;
    width: 80px;
    margin: 0px 10px;
}

.admin-date-picker {
    display: flex;
    flex-flow: row;
    width: calc(100% - 20px);
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
    padding: 5px 10px;
	box-shadow: 0 0 5px rgba(0,0,0,0.15);
	border: 1px solid rgb(87, 87, 87,0.15);
    background-color: white;
	border-radius: 5px;
	overflow: hidden;
}

.admin-date-label {
    font-size: 16px;
    font-weight: 700;
    color: black;
    margin: auto 10px auto 5px;
}

@media (max-width: 1024px) {
	
}
	
@media (max-width: 768px) {
    .book-container {
        padding: 15px 10px;
    }
    
}
	
@media (max-width: 425px) {
    .admin-date-picker {
        display: block;
    }
    
    .book-date-time {
        flex-direction: column;
    }

    .book-date-control {
        display: block;
        margin-right: 5px;
    }
    
    .book-time-controls {
        margin: 10px -10px;
    }

    .book-time-control {
        display: inline-block;
        margin: 0px 10px;
    }
    
    .book-container {
        padding: 15px 5px;
    }
}
